import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'reactstrap';
import { layout, RoundedButton, gray4, mediaQuery } from '@lumoslabs/react_lux';
import { StyleSheet, css } from 'aphrodite';

// utils
import i18n from 'utils/i18n';

// styles
import buttonStyles from 'styles/helpers/buttons';

// child components
import Layout from 'components/layout';
import Metatags from 'components/Metatags';
import GamesBreadcrumbList from './GamesBreadcrumbList';
import AccentCircleWrapper from './AccentCircleWrapper';
import GameDescription from './GameDescription';
import BrainAttributeDescription from './BrainAttributeDescription';
import GameHeroImage from './GameHeroImage';
import GameCardCollection from '../shared/GameCardCollection';
import SchemaDotOrg from './SchemaDotOrg';

const styles = StyleSheet.create({
  container: {
    borderBottom: `1px solid ${gray4}`,
    [mediaQuery.maxWidth.tabletLarge]: {
      width: '100%',
      maxWidth: 'none'
    }
  },
  imageContainer: {
    [mediaQuery.maxWidth.tabletLarge]: {
      alignItems: 'center',
      alignContent: 'center'
    }
  }
});

function cleanString(str) {
  str.replace(/<[^>]*>/g, '');
  return str.replace(/\n|\r/g, ' ');
}

function IndividualGamePage({ data: { contentfulIndividualGamePage: content }, location, pageContext }) {
  const { locale, pagePath } = pageContext;
  const {
    game,
    relatedGames,
    metaDescription,
    accentColor,
    heroImageAltText: optionalImageAltText,
    heroImage: { fluid },
    ctaText,
    ctaUrl
  } = content;
  const {
    name,
    longDescription: {
      longDescription: longDescriptionPlainText,
      childMarkdownRemark: { html: longDescriptionHtml }
    },
    brainAttributes,
    cardImage: { cardImage, twitterImage }
  } = game;

  const description = metaDescription ? metaDescription.metaDescription : cleanString(longDescriptionPlainText);
  const heroImageAltText = optionalImageAltText || cleanString(longDescriptionPlainText);

  return (
    <Layout locale={locale} location={location} isStickyHeader forceWhiteHeader>
      <Metatags
        title={name}
        description={description}
        type="website"
        canonical={pagePath}
        locale={locale}
        thumbnail={cardImage.src}
        imageWidth={cardImage.width}
        imageHeight={cardImage.height}
        twitterImage={twitterImage.url}
      />
      <AccentCircleWrapper accentColor={accentColor}>
        <Container className={css(layout.defaultContainerProperties, styles.container)}>
          <Row>
            <Col xs="12" md="6" className={css(layout.alignTop, layout.centerHorizontal)}>
              <GameHeroImage src={fluid} alt={heroImageAltText} />
            </Col>
            <Col xs="12" md="6">
              <GamesBreadcrumbList
                locale={locale}
                pathname={location.pathname}
                category={brainAttributes[0].brainAttributeCategory.name}
                urlSlug={brainAttributes[0].brainAttributeCategory.urlSlug}
              />
              <GameDescription name={name} longDescription={longDescriptionHtml} />
              <BrainAttributeDescription
                name={brainAttributes[0].name}
                shortDescription={brainAttributes[0].shortDescription}
              />
              <RoundedButton
                value={ctaText || i18n.t('Games.IndividualGamePage.signUp')}
                trackLocation="body"
                trackButtonPress="goto_signup"
                styles={[buttonStyles.buttonDefaults]}
                href={ctaUrl || '/sign_up'}
              />
            </Col>
          </Row>
        </Container>
      </AccentCircleWrapper>
      <GameCardCollection locale={locale} games={relatedGames || []} />
      <SchemaDotOrg game={game} />
    </Layout>
  );
}

IndividualGamePage.propTypes = {
  data: PropTypes.shape({
    contenfulGame: PropTypes.shape({
      name: PropTypes.string,
      longDescription: PropTypes.shape({
        childMarkdownRemark: PropTypes.shape({
          html: PropTypes.node
        })
      }).isRequired,
      brainAttributes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          shortDescription: PropTypes.shape({
            shortDescription: PropTypes.string
          }),
          brainAttributeCategory: PropTypes.shape({
            name: PropTypes.string
          })
        })
      ).isRequired,
      metaDescription: PropTypes.shape({
        metaDescription: PropTypes.string.isRequired
      }).isRequired
    }),
    relatedGames: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  location: PropTypes.shape({
    href: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    pagePath: PropTypes.string.isRequired
  }).isRequired
};

export default IndividualGamePage;

export const pageQuery = graphql`
  query IndividualGamePageQuery($urlSlug: String!, $locale: String!) {
    contentfulIndividualGamePage(game: { urlSlug: { eq: $urlSlug } }, node_locale: { eq: $locale }) {
      heroImage {
        fluid(maxWidth: 657, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroImageAltText
      accentColor
      metaDescription {
        metaDescription
      }
      ctaText
      ctaUrl
      game {
        ...gameSchemaFragment
        name
        longDescription {
          longDescription
          childMarkdownRemark {
            html
          }
        }
        brainAttributes {
          name
          shortDescription
          brainAttributeCategory {
            name
            urlSlug
          }
        }
        cardImage {
          cardImage: fixed(width: 1200, height: 630) {
            aspectRatio
            width
            height
            src
          }
          twitterImage: file {
            url
          }
        }
      }
      relatedGames {
        ...gameCardFragment
      }
    }
  }
`;
