import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { css, StyleSheet } from 'aphrodite';

import { fontStyles } from '@lumoslabs/react_lux';

const styles = StyleSheet.create({
  container: {
    margin: '40px 0'
  },
  header: {
    marginBottom: 16
  }
});

function BrainAttributeDescription({ name, shortDescription }) {
  return (
    <div className={css(styles.container)}>
      <h2 className={css(fontStyles.h4, styles.header)}>{name}</h2>
      <p className={css(fontStyles.p)}>{ReactHtmlParser(shortDescription)}</p>
    </div>
  );
}

BrainAttributeDescription.propTypes = {
  name: PropTypes.string,
  shortDescription: PropTypes.string
};

BrainAttributeDescription.defaultProps = {
  name: '',
  shortDescription: ''
};

export default BrainAttributeDescription;
