import React from 'react';
import PropTypes from 'prop-types';
import ReactHTMLParser from 'react-html-parser';
import { css, StyleSheet } from 'aphrodite';

// styles
import { fontStyles } from '@lumoslabs/react_lux';

const styles = StyleSheet.create({
  header: {
    margin: '16px 0'
  }
});

function GameDescription({ name, longDescription }) {
  return (
    <div>
      <h1 className={css(fontStyles.h1, styles.header)}>{name}</h1>
      <div className={css(fontStyles.p)}>{ReactHTMLParser(longDescription)}</div>
    </div>
  );
}

GameDescription.propTypes = {
  name: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired
};

export default GameDescription;
