import React from 'react';
import PropTypes from 'prop-types';
import BreadcrumbList from 'components/ui/navigation/BreadcrumbList';

function formatCategory(urlSlug) {
  return urlSlug.replace(' ', '-').toLowerCase();
}

// For SEO purposes, we're using "word-games" as the url for games with the brain attribute category "Language"
function categoryName(urlSlug) {
  return urlSlug === 'language' ? 'word' : urlSlug;
}

function categoryPath(urlSlug) {
  return `${formatCategory(categoryName(urlSlug))}-games`;
}

function addCategoryToPath(pathname, category, urlSlug) {
  const paths = pathname.split('/').filter(pathItem => pathItem.length > 0);
  paths.splice([paths.length - 1], 0, categoryPath(urlSlug));
  return paths.join('/');
}

function GamesBreadcrumbList({ locale, pathname, category, urlSlug }) {
  return <BreadcrumbList locale={locale} pathname={addCategoryToPath(pathname, category, urlSlug)} />;
}

GamesBreadcrumbList.propTypes = {
  locale: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  urlSlug: PropTypes.string.isRequired
};

export default GamesBreadcrumbList;
