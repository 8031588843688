import React from 'react';
import PropTypes from 'prop-types';
import { mediaQuery } from '@lumoslabs/react_lux';
import { css, StyleSheet } from 'aphrodite';

// TODO: add contentful validations to ensure that we're getting the right values
const styles = StyleSheet.create({
  parent: {
    position: 'relative'
  },
  circle: {
    position: 'absolute',
    borderRadius: '50%',
    width: '200vw',
    height: '200vw',
    left: '-110vw',
    top: '-100vw',
    [mediaQuery.minWidth.mobileLarge]: {
      top: '-110vw'
    },
    [mediaQuery.minWidth.tablet]: {
      width: '90vw',
      left: '-45vw',
      height: '90vw',
      top: 'calc(-90vw + 580px)'
    },
    [mediaQuery.minWidth.desktop]: {
      width: '70vw',
      left: '-23vw',
      height: '70vw',
      top: 'calc(-70vw + 659px)'
    }
  },
  placeholderConstraint: {
    height: '150vw'
  }
});

// TODO, after receiving production assets, remove placeholderConstraint styles
function AccentCircleWrapper({ children, accentColor }) {
  const inlineStyles = StyleSheet.create({
    setAccent: {
      backgroundColor: `${accentColor}`
    }
  });
  return (
    <div className={css(styles.parent)}>
      <div className={css(inlineStyles.setAccent, styles.circle, styles.placeholderConstraint)} />
      {children}
    </div>
  );
}

AccentCircleWrapper.propTypes = {
  accentColor: PropTypes.string,
  children: PropTypes.node.isRequired
};

AccentCircleWrapper.defaultProps = {
  accentColor: 'transparent'
};

export default AccentCircleWrapper;
