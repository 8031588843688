import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import { StyleSheet, css } from 'aphrodite';
import { mediaQuery } from '@lumoslabs/react_lux';

const styles = StyleSheet.create({
  image: {
    width: '62vw',
    maxWidth: 657,
    height: 'auto',
    marginBottom: 36,
    [mediaQuery.minWidth.tablet]: {
      marginBottom: 0,
      marginTop: 36
    },
    [mediaQuery.minWidth.desktop]: {
      width: '37vw',
      minWidth: 657,
      marginRight: 120
    }
  },
  placeholderConstraint: {
    [mediaQuery.minWidth.desktop]: {
      minWidth: 457
    }
  }
});

// TODO, after receiving production assets, remove placeholderConstraint styles
function GameHeroImage({ src, alt }) {
  return <Image className={css(styles.image, styles.placeholderConstraint)} fluid={src} alt={alt} />;
}

GameHeroImage.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.shape({
    fluid: PropTypes.string
  }).isRequired
};

export default GameHeroImage;
