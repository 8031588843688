import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { Generic } from 'react-structured-data';

function GameSchema({ game }) {
  const {
    shortDescription,
    longDescription: { longDescription },
    cardImage: {
      file: { imageUrl }
    },
    name,
    freelyAccessible
  } = game;
  const isAccessibleForFree = freelyAccessible ? 'Yes' : 'No';
  const description = shortDescription || longDescription;
  const thumbnailUrl = `https:${imageUrl}`;
  return (
    <Generic
      key={name}
      jsonldtype="Game"
      schema={{ isAccessibleForFree, copyrightHolder: 'Lumos Labs (Lumosity)', description }}
    >
      <Generic type="numberOfPlayers" jsonldtype="QuantitativeValue" schema={{ value: '1' }} />
      <Generic type="image" jsonldtype="ImageObject" schema={{ thumbnailUrl, name }} />
    </Generic>
  );
}

GameSchema.propTypes = {
  game: PropTypes.shape({
    longDescription: PropTypes.shape({ longDescription: PropTypes.string }),
    shortDescription: PropTypes.string,
    cardImage: PropTypes.shape({ file: PropTypes.shape({ imageUrl: PropTypes.string }) }),
    name: PropTypes.string.isRequired,
    freelyAccessible: PropTypes.bool.isRequired
  }).isRequired
};

export const gameSchemaFragment = graphql`
  fragment gameSchemaFragment on ContentfulGame {
    name
    shortDescription
    longDescription {
      longDescription
    }
    freelyAccessible
    cardImage {
      file {
        imageUrl: url
      }
    }
  }
`;

export default GameSchema;
