import React from 'react';
import PropTypes from 'prop-types';
import { JSONLD } from 'react-structured-data';

import GameSchema from '../shared/SchemaDotOrg/GameSchema';

function SchemaDotOrg({ game }) {
  return <JSONLD dangerouslyExposeHtml>{GameSchema({ game })}</JSONLD>;
}

SchemaDotOrg.propTypes = {
  game: PropTypes.shape({
    longDescription: PropTypes.shape({ longDescription: PropTypes.string }),
    shortDescription: PropTypes.string,
    cardImage: PropTypes.shape({ file: PropTypes.shape({ imageUrl: PropTypes.string }) }),
    name: PropTypes.string.isRequired,
    freelyAccessible: PropTypes.bool.isRequired
  }).isRequired
};

export default SchemaDotOrg;
